import React from 'react'

const Footer = ({ projects }) => {
   return (
      <footer>
         <div className='left-footer'>
            <div>
               <p>Copyright &#169; {new Date().getFullYear()} Kevin Penney</p>
               <p>Contact: kevin@penneyprojects.com</p>
            </div>
            <a href='https://www.freepik.com/photos/background'>
               Background photo created by rawpixel.com - www.freepik.com
            </a>
         </div>
         <nav>
            <h4>Project List</h4>
            {projects.map(({ id, link, title, title1 }) => (
               <a key={id} href={link} title={title}>
                  <li dangerouslySetInnerHTML={{ __html: title1 ? title1 : title }}></li>
               </a>
            ))}
         </nav>
      </footer>
   )
}

export default Footer
