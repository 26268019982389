import invoicePic from '../images/invoice.jpg'
import investPic from '../images/investmentco.jpg'
import roadTripPic from '../images/weather.jpg'
import yarnTopiaPic from '../images/yarntopia-416pxw-tiny.jpg'
import rickyPic from '../images/Ricky4-500pxw-tiny.jpg'
import investmentVid from '../videos/InvestmentCo.mp4'
import invoiceVid from '../videos/Invoice.mp4'
import roadTripVid from '../videos/RoadTrip.mp4'

const projects = [
   {
      id: 1,
      pic: invoicePic,
      title: 'Invoicing App',
      descrip:
         'Invoicing software for small business. Full invoicing functionality: create, edit, delete, template and save to your local machine. Secure user login. Click the Demo Account button at the login screen to try it out.',
      tech: 'Vanilla JavaScript, HTML/CSS, Node.js, Express, Puppeteer, Passport.js',
      learned:
         'I made the front end before I learned a framework. It was a lot of lines of code in two long files. It became unruly with all of the dynamically created content in JavaScript. Time to learn React!',
      link: 'https://invoice.penneyprojects.com',
      vid: invoiceVid,
      color: '#177ec9'
   },
   {
      id: 2,
      pic: investPic,
      title: 'Investment Co',
      descrip:
         'Website for an investment company. The front page is a slideshow of transitions and backgrounds.',
      tech:
         'React, JavaScript, HTML/CSS, Functional Components, React Hooks, React Router, React Transition Group',
      learned:
         'I followed a Figma design. I learned how to transform, transition and animate with react, as well as swap backgrounds on scroll. Utilizing reusable react templates for the investment pages saves a ton of time and filesize',
      link: 'https://investmentco.penneyprojects.com',
      vid: investmentVid,
      color: '#082e68'
   },
   {
      id: 3,
      pic: roadTripPic,
      title: 'Road Trip Weather Map',
      title1: 'Road Trip <span>Weather Map</span>',
      descrip:
         ' Provides the weather for your upcoming road trip. Adds functionality to google maps to access the weather as well as the ability to schedule a layover at each stop.',
      tech:
         'Vanilla JavaScript, HTML/CSS, Geolocation, Open Weather Map, Google Cloud Platform - GoogleMaps API, Directions Service, Places Autocomplete',
      learned: 'How to integrate with multiple APIs or microservices to make one greater app',
      link: 'https://weather.penneyprojects.com',
      vid: roadTripVid,
      color: '#606f7a'
   },
   {
      id: 4,
      pic: yarnTopiaPic,
      title: 'YarnTopia Store',
      descrip: 'Storefront for selling yarn creations',
      tech: 'React, JavaScript, HTML/CSS, Snipcart Module',
      learned:
         'My first react project - I learned a lot. I added some transitions and also learned how work with the Snipcart module to open an ecommerce store.',
      link: 'https://store.penneyprojects.com'
   },
   {
      id: 5,
      pic: rickyPic,
      title: 'Photography by Ricky Kelley',
      title1: 'Photography by <span>Ricky Kelley</span>',
      descrip: 'Professional Photography Portfolio',
      tech: 'React, JavaScript, HTML/CSS',
      learned: 'How to make a picture carousel and apply CSS filters.',
      link: 'https://dev.penneyprojects.com'
   }
   // {
   //    id: ,
   //    pic: ,
   //    title: '',
   //    title1: '',
   //    descrip: '',
   //    tech: '',
   //    learned: '',
   //    link: '',
   //    vid: ,
   //    color:
   // },
]

export default projects
