import React from 'react'
// import roadTripVid from '../videos/RoadTrip.mp4'

// onMouseOver={e => e.target.play()}
// onMouseOut={e => e.target.pause()}

const Invoicing = ({
   project: { title, title1, descrip, tech, learned, link, vid, color },
   vidOnLeft
}) => {
   return (
      <div className={`project-main ${vidOnLeft ? 'video-left' : 'video-right'}`}>
         <h2 dangerouslySetInnerHTML={{ __html: title1 ? title1 : title }}></h2>
         <div className='descrip' style={{ backgroundColor: color }}>
            <p>{descrip}</p>
            <hr />
            <h3>Technologies</h3>
            <p>{tech}</p>
            <h3>What I Learned</h3>
            <p>{learned}</p>
            <a href={link} target='_blank' className='link-button' rel='noreferrer'>
               GO TO SITE
            </a>
         </div>
         <a href={link} target='_blank' rel='noreferrer' title={`Go to ${title}`}>
            <video src={vid} autoPlay muted loop>
               Sorry, this browser does not support the video.
            </video>
         </a>
      </div>
   )
}

export default Invoicing
