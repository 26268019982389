import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Bio = () => {
   const [heroLoaded, setHeroLoaded] = useState(0)

   return (
      <section className={`bio ${heroLoaded && 'hero-loaded-animate'}`}>
         <h1>Penney Projects</h1>
         <div className='bubble'>
            <p>Welcome to my site!</p>
            <p>You can see my projects</p>
            <p>below. But first, here is</p>
            <p>a little bit about me</p>
         </div>
         <div className='img-div'>
            <StaticImage
               src='../images/me-442pxw-tiny.png'
               alt='Me'
               onLoad={() => setHeroLoaded(1)}
               loading='eager'
            />
         </div>
         <div className='bio-details'>
            <h4>Name:</h4>
            <p>Kevin Penney</p>
            <h4>Contact:</h4>
            <p>kevin@penneyprojects.com</p>
            <h4>Passion:</h4>
            <p>Coding & Technology - building, fixing & figuring</p>
            <h4>Skills:</h4>
            <p>JavaScript, HTML/CSS, React, Node.js, Python</p>
         </div>
      </section>
   )
}

export default Bio
