import React from 'react'
import Head from '../components/Head'
import Nav from '../components/Nav'
import Bio from '../components/Bio'
import FeaturedProjectVideo from '../components/FeaturedProjectVideo'
import AdditionalProject from '../components/AdditionalProject'
import Footer from '../components/Footer'

import '../styles/App.scss'

import projects from '../projectList/projects'

function Index() {
   return (
      <div className='App'>
         <Head />
         <div className='header-background' />
         <Nav projects={projects} />
         <Bio />
         <section className='featured-projects'>
            <FeaturedProjectVideo project={projects[0]} vidOnLeft={false} />
            <FeaturedProjectVideo project={projects[1]} vidOnLeft={true} />
            <FeaturedProjectVideo project={projects[2]} vidOnLeft={false} />
         </section>
         <section className='container-additional'>
            <h2>Additional Projects</h2>
            <div className='project-grid'>
               {projects.slice(3).map(project => (
                  <AdditionalProject key={project.id} project={project} />
               ))}
            </div>
         </section>
         <Footer projects={projects} />
      </div>
   )
}

export default Index
