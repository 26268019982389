import React from 'react'

const AdditionalProject = ({ project: { pic, title, descrip, tech, learned, link } }) => {
   return (
      <div className='project-card'>
         <img src={pic} alt={title} loading='lazy' />
         <a href={link} title={title} target='_blank' rel='noreferrer'>
            <div className='card-data'>
               <h3>{title}</h3>
               <p>{descrip}</p>
               <h4>Technologies</h4>
               <p>{tech}</p>
               <h4>What I Learned</h4>
               <p>{learned}</p>
            </div>
         </a>
      </div>
   )
}

export default AdditionalProject
